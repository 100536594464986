// See: https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
// ## function declaration
function scrollEventThrottle(fn) {
  let last_known_scroll_position = 0;
  let ticking = false;
  window.addEventListener("scroll", function () {
    let previous_known_scroll_position = last_known_scroll_position;
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        fn(last_known_scroll_position, previous_known_scroll_position);
        ticking = false;
      });
      ticking = true;
    }
  });
}

// Find header
const headerOnDown = document.querySelector(".header-wine");
const screenWidht = window.innerWidth;
const headerWidth = headerOnDown.offsetWidth;

const headerPaddingOnScroll = (screenWidht - headerWidth) / 2;

// Need to Fix "jump & freez" when scroll to top
const body = document.querySelector(".wine-no-scroll");
const endFixedOnScroll =
  headerOnDown.offsetHeight +
  document.querySelector(".first-screen").offsetHeight * 2.5;

// ## function invocation
scrollEventThrottle((scrollPos, previousScrollPos) => {
  if (previousScrollPos > scrollPos) {
    headerOnDown.classList.add("fixed-scroll-to-top");
    headerOnDown.style.paddingLeft = `${headerPaddingOnScroll}px`;
    headerOnDown.style.paddingRight = `${headerPaddingOnScroll}px`;
    body.style.paddingTop = `${
      document.querySelector(".header-wine").offsetHeight
    }px`;
  }

  // Need Reset Styles On First Screen And When Scroll Down:
  if (scrollPos < endFixedOnScroll || previousScrollPos < scrollPos) {
    headerOnDown.classList.remove("fixed-scroll-to-top");
    body.style.paddingTop = "";
    headerOnDown.style.paddingLeft = "";
    headerOnDown.style.paddingRight = "";
    body.style.paddingTop = "";
  }
});
