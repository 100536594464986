import AOS from "aos";

const spinnerBg = document.querySelector(".spinner__loader");
const body = document.querySelector(".wine-no-scroll");

window.addEventListener("load", () => {
  body.classList.remove("wine-no-scroll");
  spinnerBg.classList.add("hide");
  AOS.init();
});
