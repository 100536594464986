import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const videoBottle = document.querySelector(".video-background");
// const iframeIphone = document.querySelector(
//   ".first__part .iphone__wrapper .iphone"
// );

const videoHeight = videoBottle.offsetHeight;
// const iframeIphoneHeght = iframeIphone.offsetHeight;

let screenSize = window.innerWidth;

function reportWindowSize() {
  // let screenSize = window.innerWidth;
  // if (screenSize < 1440) {
  //   let translateVideo =
  //     iframeIphone.getBoundingClientRect().left +
  //     iframeIphone.getBoundingClientRect().width / 2 -
  //     screenSize / 2;
  //   videoBottle.style.transform = `translateX(calc(-50% + ${translateVideo}px)`;
  // }
}

window.onload = reportWindowSize;
window.onresize = reportWindowSize;

let startScrollPoint = "top +=" + ((videoHeight * 2.1) / 2).toString();
let endScrollPoint = "bottom -=" + (videoHeight * 0.48).toString(); // 0.9

if (screenSize < 1200) {
  startScrollPoint = "top -=" + (videoHeight / 2).toString();
  // endScrollPoint = "bottom -=" + (videoHeight * 0.35).toString(); // 0.75
}

if (screenSize < 992) {
  startScrollPoint = "top -=" + (videoHeight / 2).toString();
  // endScrollPoint = "bottom -=" + (videoHeight * 0.35).toString(); // 1
}

if (screenSize < 768) {
  startScrollPoint = "top -=" + (videoHeight / 1.5).toString();
  // endScrollPoint = "bottom -=" + (videoHeight * 0.35).toString(); // 1.15
}

ScrollTrigger.matchMedia({
  "(min-width: 576px)": function () {
    gsap.utils.toArray(".video-background").forEach((video) =>
      videoScrub(video, {
        scrollTrigger: {
          trigger: ".first__part",
          start: startScrollPoint,
          end: endScrollPoint,
          scrub: true,
          toggleClass: {
            className: "show-video",
            targets: ".video-background, .inventory-screen .iphone--bottle",
          },
          // markers: true,
        },
      })
    );
  },
});

function videoScrub(video, vars) {
  video = gsap.utils.toArray(video)[0]; // in case selector text is fed in.
  let once = (el, event, fn) => {
      let onceFn = function () {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
      };
      el.addEventListener(event, onceFn);
      return onceFn;
    },
    prepFunc = () => {
      video.play();
      video.pause();
    },
    prep = () => once(document.documentElement, "touchstart", prepFunc),
    // src = video.currentSrc || video.src,
    tween = gsap.fromTo(
      video,
      { currentTime: 0 },
      {
        paused: true,
        immediateRender: false,
        currentTime: video.duration || 1,
        ease: "none",
        ...vars,
      }
    ),
    resetTime = () =>
      (tween.vars.currentTime = video.duration || 1) && tween.invalidate();
  prep();
  video.readyState ? resetTime() : once(video, "loadedmetadata", resetTime);
  return tween;
}
