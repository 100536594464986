const body = document.querySelector("body");
let screenSize = window.innerWidth;

let menuOpened = false;

const buttonsInMobile = document.querySelectorAll(".header-wine .header__btn");
const headerMenu = document.querySelector(".header-wine");

if (buttonsInMobile !== null && screenSize < 992) {
  buttonsInMobile.forEach((headerButton) => {
    headerButton.addEventListener("click", () => {
      menuOpened = !menuOpened;

      if (menuOpened) {
        body.classList.add("wine-no-scroll");
        headerMenu.classList.add("opened-mobile-menu");
        document.querySelector(".header__btn--menu").style.background =
          "url('/img/sprites/sprite.svg#close') no-repeat center";
      } else {
        body.classList.remove("wine-no-scroll");
        headerMenu.classList.remove("opened-mobile-menu");
        document.querySelector(".header__btn--menu").style.background =
          "url('/img/sprites/sprite.svg#burger') no-repeat center";
      }
    });
  });
}
