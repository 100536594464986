// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

// ScrollTrigger.matchMedia({
//   "(min-width: 768px)": function () {
//     gsap.utils.toArray(".video__parallax").forEach((section) => {
//       const innerVideoHeight = section.offsetHeight;
//       section.bg = section.querySelector(".videoWrapper");

//       // Do the parallax effect on each section

//       section.bg.style.top = `${innerVideoHeight}px`;

//       gsap.to(section.bg, {
//         top: `${-innerVideoHeight / 5}px`,
//         ease: "none",
//         scrollTrigger: {
//           trigger: section,
//           scrub: true,
//         },
//       });
//     });
//   },
// });
