import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.matchMedia({
  "(min-width: 576px)": function () {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".first-screen",
        scrub: true,
        pin: true,
        start: "top +=94",
        end: () =>
          `+=${document.querySelector(".first-screen").offsetHeight * 2}`,
        toggleClass: { className: "fixed", targets: ".header-wine" },
        // onEnter, onLeave, onEnterBack, onLeaveBack
        // toggleActions: "restart none none reset",
        onLeave: function () {
          tl.scrollTrigger.toggleClass = {
            className: "fixed-up",
            targets: ".header-wine",
          };
        },
      },
    });

    // Animations For Text Elements
    gsap.from(".gsap__title, .gsap__text, .btn-animation", {
      autoAlpha: 0,
      ease: "power2.in",
      delay: 0.5,
      duration: 0.75,
    });
    tl.from(
      ".first-screen .txt--wix",
      { autoAlpha: 0, yPercent: 100, ease: "power2" },
      ">"
    );
  },
});
