import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let screenSize = window.innerWidth;

ScrollTrigger.matchMedia({
  "(min-width: 768px)": function () {
    let topStart = "top +=40%";
    let bottomStop = "bottom +=40%";

    let startScrollPoint = "top +=1000";
    let stopScrollPoint = "bottom +=950";

    let triggerArea = ".specialty-screen";

    if (1201 < screenSize < 1440) {
      startScrollPoint = topStart;
      stopScrollPoint = bottomStop;
    }

    if (screenSize < 1201) {
      startScrollPoint = "top +=500";
      stopScrollPoint = "bottom +=300";
    }

    if (screenSize < 992) {
      startScrollPoint = "top +=300";
      stopScrollPoint = "bottom +=100";
    }

    gsap.utils.toArray(".video-packing").forEach((video) =>
      videoScrub(video, {
        scrollTrigger: {
          trigger: triggerArea, // No Change !
          start: startScrollPoint,
          end: stopScrollPoint,
          scrub: true,
          toggleClass: { className: "show-video", targets: ".wine--box" },
        },
      })
    );
  },
});

function videoScrub(video, vars) {
  // video.style.height = `${screenHeight * 1.25}px`;
  video = gsap.utils.toArray(video)[0]; // in case selector text is fed in.
  let once = (el, event, fn) => {
      let onceFn = function () {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
      };
      el.addEventListener(event, onceFn);
      return onceFn;
    },
    prepFunc = () => {
      video.play();
      video.pause();
    },
    prep = () => once(document.documentElement, "touchstart", prepFunc),
    // src = video.currentSrc || video.src,
    tween = gsap.fromTo(
      video,
      { currentTime: 0 },
      {
        paused: true,
        immediateRender: false,
        currentTime: video.duration || 1,
        ease: "none",
        ...vars,
      }
    ),
    resetTime = () =>
      (tween.vars.currentTime = video.duration || 1) && tween.invalidate();
  prep();
  video.readyState ? resetTime() : once(video, "loadedmetadata", resetTime);
  return tween;
}
