import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.matchMedia({
  "(min-width: 576px)": function () {
    const images = gsap.utils.toArray(".gsap__image");
    images.forEach((imageScroll, i) => {
      const tl_Gallery = gsap.timeline({
        scrollTrigger: {
          trigger: ".first-screen .gsap__title",
          start: 200,
          end: "bottom +=50%",
        },
      });

      const q = gsap.utils.selector(imageScroll);
      const gsapImg = q(".gsap__image--scroll");
      const startImg = q(".gsap__image--start");

      startImg.forEach((group) => {
        tl_Gallery.from(
          group,
          { yPercent: 101, delay: `${i * 0.15 + 0.15}` },
          "<+=0.5"
        );
      });

      gsapImg.forEach((group) => {
        tl_Gallery.fromTo(
          group,
          {
            yPercent: 0,
            opacity: 0,
            delay: `${i * 0.15 + 0.15}`,
            duration: 0.25,
          },
          { yPercent: -100, opacity: 1 },
          ">+150%"
        );
      });
    });
  },
});
