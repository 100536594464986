const screenHeight = window.innerHeight;
const screenWidth = window.innerWidth;

const leftBlock = document.querySelector(".packing-screen .left__side--inner");
const blockFixedArea = document.querySelector(".packing-screen");

const contentWidht = blockFixedArea.offsetWidth;

const startFixed =
  blockFixedArea.getBoundingClientRect().top +
  1600 +
  leftBlock.offsetHeight / 2 -
  150; // 1600 Scroll GSAP Height On First Screen

// const endFixed =
//   blockFixedArea.getBoundingClientRect().bottom +
//   screenHeight +
//   leftBlock.offsetHeight * 1.5;

const endFixed =
  blockFixedArea.getBoundingClientRect().bottom +
  screenHeight +
  leftBlock.offsetHeight;

const leftBlockLeftPosition = (screenWidth - contentWidht) / 2 + 40;

// window.onscroll = function () {
//   let posTop = window.scrollY;
//   if (posTop >= startFixed && posTop < endFixed && screenWidth > 991) {
//     leftBlock.classList.add("fixed");
//     leftBlock.style.left = `${leftBlockLeftPosition}px`;
//   } else {
//     leftBlock.classList.remove("fixed");
//     leftBlock.style.left = "0";
//   }
// };
