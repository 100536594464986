import Swiper from "swiper";

const swiper = new Swiper(".wine__bottles", {
  speed: 400,
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    320: {
      slidesPerView: 3,
      centeredSlides: true,
      spaceBetween: 12,
      initialSlide: 1,
    },
  },
});

swiper.init();
