let buttons = document.querySelectorAll(".btn--modal");

if (buttons !== null) {
  for (const button of buttons) {
    button.addEventListener("click", () => {
      document.querySelector("body").classList.add("modal-opened");
      document.querySelector(".modal__shadow").classList.add("modal--show");
    });
  }
}
